import React from "react";

const style = {
  // position: "absolute",
  bottom: 0,
  left: 0,
  right: -50,
  zIndex: 700,
  minHeight: "55px",
  color: "white",
  background: "black",
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  paddingLeft: 10,
  justifyContent: "center",
};
const Footer = (props) => {
  return (
    <div style={style}>
      <small>
        Designed and built by Adam Woolf @{" "}
        <a style={{ color: "white" }} href='https://www.webspinner.eu'>
          WebSpinner.eu
        </a>
      </small>
    </div>
  );
};

export default Footer;
