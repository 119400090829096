import React, { useState, useEffect } from "react";
import "./BiogStyles.scss";
import { getBiog } from "../../contentful";
import marked from "marked";
const Biog = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getBiog().then((data) => setContent(data[0].fields));
  }, []);
  return (
    <div className='page biog-page '>
      {content.image && <img src={content.image.fields.file.url} />}
      {content.text && (
        <div dangerouslySetInnerHTML={{ __html: marked(content?.text) }} />
      )}
    </div>
  );
};

export default Biog;
