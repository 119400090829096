import React from "react";
import "./YoutubeVidStyles.scss";

const YoutubeVid = ({ video, className }) => {
  return (
    <iframe
      className={className}
      width='560'
      height='315'
      src={`https://www.youtube.com/embed/${video}`}
      title='YouTube video player'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    ></iframe>
  );
};

export default YoutubeVid;
