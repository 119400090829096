const client = require("contentful").createClient({
  space: "lvateium6kac",
  accessToken: "kwiM31AQLXMvxBJzcTy1kV1mh-5ua-XXD3wDdiEFCbo",
});

const getRecordings = () =>
  client
    .getEntries({ content_type: "recording", order: "fields.order" })
    .then((response) => response.items);

const getStoreItems = () =>
  client
    .getEntries({ content_type: "storeItem" })
    .then((response) => response.items);

const getYoutube = () =>
  client
    .getEntries({ content_type: "youtube" })
    .then((response) => response.items);

const getBiog = () =>
  client
    .getEntries({ content_type: "biographyPage" })
    .then((response) => response.items);

const getEnsembles = () =>
  client
    .getEntries({ content_type: "ensemble", order: "fields.order" })
    .then((response) => response.items);

const getSoloProgrammes = () =>
  client
    .getEntries({ content_type: "soloProgramme", order: "fields.order" })
    .then((response) => response.items);

const getTeachingPage = () =>
  client
    .getEntries({ content_type: "researchAndTeaching" })
    .then((response) => response.items);

const getReviews = () =>
  client
    .getEntries({ content_type: "review" })
    .then((response) => response.items);

export {
  getReviews,
  getTeachingPage,
  getRecordings,
  getYoutube,
  getStoreItems,
  getBiog,
  getEnsembles,
  getSoloProgrammes,
};
