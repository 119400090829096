import React, { useEffect } from "react";
import "./ContactStyles.scss";
import KC from "../../assets/KC.jpg";
import PageTitle from "../../components/PageTitle/PageTitle";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className='page contact'>
      <PageTitle title='Contact me' />
      <div className='contact__image'>
        <img src={KC} />
      </div>
      <div>
        <p> I am always happy to hear from visitors to my website.</p>
        <p>
          {" "}
          To get in touch, please send an email to{" "}
          <a href='mailto:info@kathryncok.com'> info@kathryncok.com</a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
