import React from "react";
import Kathryn from "../../assets/KC2.jpg";
import "./HeroStyles.scss";
import Testimonials from "../Testimonials/Testimonials";

const Hero = () => {
  return (
    <div className='hero'>
      <div className='hero__testimonials'>
        <Testimonials />
      </div>
      <div className='hero__image'>
        <img src={Kathryn} />
      </div>
    </div>
  );
};

export default Hero;
