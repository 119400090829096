import React, { useState, useEffect } from "react";
import "./ResearchAndTeachingStyles.scss";
import { getTeachingPage } from "../../contentful";
import PageTitle from "../../components/PageTitle/PageTitle";
import marked from "marked";

const ResearchAndteaching = () => {
  const [content, setContent] = useState({});
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getTeachingPage().then((data) => setContent(data[0].fields));
  }, []);
  return (
    <div className='page'>
      <PageTitle title='Research & Teaching' />
      <div className='teaching-page'>
        {content.text1 && (
          <section
            dangerouslySetInnerHTML={{ __html: marked(content?.text1) }}
          />
        )}
        <div className='teaching-page__image-container'>
          {content.image2 && (
            <div className='teaching-page__image--l'>
              <img src={content.image2.fields.file.url} />
            </div>
          )}

          {content.image1 && (
            <div className='teaching-page__image--p'>
              <img src={content.image1.fields.file.url} />
            </div>
          )}
        </div>
        {content.text2 && (
          <section
            dangerouslySetInnerHTML={{ __html: marked(content?.text2) }}
          />
        )}

        {content.text4 && (
          <section
            dangerouslySetInnerHTML={{ __html: marked(content?.text4) }}
          />
        )}

        {content.text3 && (
          <section
            dangerouslySetInnerHTML={{ __html: marked(content?.text3) }}
          />
        )}

        <div className='teaching-page__gallery'>
          {content.gallery &&
            content.gallery.map((image) => (
              <div className='teaching-page__gallery__image'>
                <img src={image.fields.file.url} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ResearchAndteaching;
