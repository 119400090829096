import React, { useState, useEffect } from "react";
import "./RecordingsStyles.scss";
import { getRecordings } from "../../contentful";
import marked from "marked";
import YoutubeVid from "../../components/YoutubeVid/YoutubeVid";
import PageTitle from "../../components/PageTitle/PageTitle";

const Recordings = () => {
  const [recordings, setRecordings] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getRecordings().then((data) => setRecordings(data));
  }, []);
  return (
    <div className='page'>
      <PageTitle title='Recordings' />
      {recordings.map((cd) => (
        <div className='recording-card'>
          <div className='recording-card__section'>
            <img
              className='recording-card__image'
              src={cd.fields.cover.fields.file.url}
            />
            <div>
              <h2>{cd.fields.title}</h2>

              <section
                dangerouslySetInnerHTML={{
                  __html: marked(cd.fields.tracklist),
                }}
              />
            </div>
          </div>
          <section
            dangerouslySetInnerHTML={{
              __html: marked(cd.fields.description),
            }}
          />

          {cd.fields.video && (
            <div className='recording-card__videos'>
              {cd.fields.video.map((v) => (
                <YoutubeVid video={v.fields.code} />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Recordings;
