import React, { useState, useEffect } from "react";
import { getStoreItems } from "../../contentful";
import "./StoreStyles.scss";
import Checkout from "./Checkout";
import StoreItem from "./StoreItem";
import PageTitle from "../../components/PageTitle/PageTitle";

const Store = () => {
  const [products, setProducts] = useState([]);
  const [basket, setBasket] = useState([]);
  const [itemCodes, setItemCodes] = useState([]);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    getStoreItems().then((data) => setProducts(data));
  }, []);

  const addItem = (item) => {
    setBasket([...basket, item]);
    setItemCodes([...itemCodes, item.fields.productId]);
  };

  const removeItem = (item) => {
    setBasket(
      basket.filter((i) => i.fields.productId !== item.fields.productId)
    );
    setItemCodes(itemCodes.filter((code) => code !== item.fields.productId));
  };

  return (
    <div>
      <div>
        <div className='store__header'>
          <PageTitle white title='Store' />
          <div className='store__info'>
            <p>
              Here you can purchase my CDs from me directly. Prices include
              shipping.
            </p>

            <h4>
              SPECIAL OFFER: Get a discount of €5 on every 2 CDs purchased.
            </h4>
            <p>
              <small>Discount added at checkout.</small>
            </p>
          </div>
        </div>
        <Checkout
          setBasket={setBasket}
          setShowCheckout={setShowCheckout}
          itemCodes={itemCodes}
          basket={basket}
          showCheckout={showCheckout}
        />
        {products.filter((p) => p.fields.isDownload).length > 0 && (
          <>
            <h3 className='store__heading'>Downloads</h3>

            <div className='store__items'>
              {products
                .filter((p) => p.fields.isDownload)
                .map((item, index) => (
                  <StoreItem
                    item={item}
                    index={index}
                    addItem={addItem}
                    removeItem={removeItem}
                    itemCodes={itemCodes}
                  />
                ))}
            </div>
          </>
        )}
        <hr />
        <h3 className='store__heading'>All Products</h3>
        <div className='store__items'>
          {products.map((item, index) => (
            <StoreItem
              item={item}
              index={index}
              addItem={addItem}
              removeItem={removeItem}
              itemCodes={itemCodes}
            />
          ))}
        </div>
      </div>
      {showCheckout && <div className='checkout__backdrop' />}
    </div>
  );
};

export default Store;
