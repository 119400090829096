import React, { useState, useEffect, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Biog from "./pages/Biog/Biog";
import Ensembles from "./pages/Ensembles/Ensembles";
// import Solo from "./pages/SoloPrograms/Solo";
import Recordings from "./pages/Recordings/Recordings";
import ResearchAndteaching from "./pages/ResearchAndteaching/ResearchAndteaching";
import Contact from "./pages/Contact/Contact";
import Store from "./pages/Store/Store";
import backgroundImage from "./assets/background.jpeg";

const App = () => {
  return (
    <>
      <div className='app-container'>
        <img src={backgroundImage} className='app-background' />
        <Header />
        <div className='pages-container'>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/biog' component={Biog} />
            <Route path='/ensembles' component={Ensembles} />
            {/* <Route path='/solo' component={Solo} /> */}
            <Route path='/recordings' component={Recordings} />
            <Route path='/research' component={ResearchAndteaching} />
            <Route path='/contact' component={Contact} />
            <Route path='/store' component={Store} />
          </Switch>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;
