import React, { useState, useEffect, useRef } from "react";
import "./StoreStyles.scss";
import PayPalButton from "react-paypal-smart-button";
import useSendEMail from "../../useSendEmail";
import { useHistory } from "react-router-dom";

const Checkout = ({
  basket,
  itemCodes,
  setShowCheckout,
  showCheckout,
  setBasket,
}) => {
  const checkoutRef = useRef();
  const history = useHistory();
  const [showPP, setShowPP] = useState(false);
  const [total, setTotal] = useState(0);
  const [continuing, setContinuing] = useState(false);
  const { sendMail } = useSendEMail();
  const now = new Date().getTime();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [country, setCountry] = useState("");
  const [height, setHeight] = useState(100);

  useEffect(() => {
    setHeight(!showPP ? "100px" : "100vh");
    if (showPP) {
      checkoutRef.current.style.overflowY = "scroll";
    } else {
      checkoutRef.current.style.overflowY = "hidden";
    }
  }, [showPP]);

  const paypalClientId =
    "AWQyJfMuuB5xjVmoi7KgGpxxg36-A8QXxNeWnSV2MKFcVFCqKxHhbFMyCH5dM3yPff5v92BBW4L39OBj";

  const closeAll = () => {
    setContinuing(false);
    setShowPP(false);
    setShowCheckout(false);
  };

  const codeString = itemCodes.join("code");

  const titleString = basket
    .map((i) => i.fields.title.replace("&", "and"))
    .join("<br/>");

  const handlePayment = () => {
    // if (hasDownloads() && !hasShippableItems()) {
    //   const codes = `codes${JSON.stringify(codeString)
    //     .replace(" ", "")
    //     .replace(/ ['"]+/g, "")}`;

    //   const html = `<div><p>Dear ${name},<br/>Thank you for your order.</p><p><a href="https://adamwoolf.com/download?t=${now}codes${codeString}">Please click here to visit the downloads page and get your item(s)</a></p><p>Best wishes, Adam</p></div>`;
    //   if (codes && name && email)
    //     sendMail(html, `Purchase of from www.adamwoolf.com`, email);
    //   setBasket([]);

    //   closeAll();

    //   history.push(`/download?t=${now}codes${codeString}`);
    // }
    if (hasShippableItems() && !hasDownloads()) {
      const html = `<div><p> Dear ${name}, <br/>Thank you for your order.</p><hr/><b>Details:</b><br/>${titleString}<hr/>${name}<br/>${email}<p><b>Delivery address:</b> <br/> ${address1}<br/>${address2}<br/>${city}<br/>${postCode}<br/>${stateProvince}<br/>${country}.<p>I will dispatch your item(s) as soon as possible.</p><p>Best wishes, Kathryn</p></div>`;
      sendMail(html, `New Purchase from www.kathryncok.com`, email);

      setBasket([]);

      closeAll();
    }

    // if (hasShippableItems() && hasDownloads()) {
    //   const html = `<div><p> Dear ${name}, <br/>Thank you for your order.</p><p><a href="https://adamwoolf.com/download?t=${now}codes${codeString}">Please click here to visit the downloads page and get your item(s)</a></p><p>I will dispatch your item(s) as soon as possible to <br/> ${address1}<br/>${address2}<br/>${city}<br/>${postCode}<br/>${stateProvince}<br/>${country}.<p>Best wishes, Adam</p></div>`;
    //   sendMail(html, `Purchase from www.kathryncok.com`, email);
    //   setBasket([]);

    //   closeAll();
    // }
  };

  const calculateTotal = (items) => {
    return items.reduce((acc, item) => {
      acc = acc + +item.fields.price;
      return acc;
    }, 0);
  };

  const shouldApplyDiscount = (total) => {
    let newTotal = total;
    if (basket.length === 2 || basket.length === 3) newTotal = total - 5;
    if (basket.length === 4 || basket.length === 5) newTotal = total - 10;
    if (basket.length === 6 || basket.length === 7) newTotal = total - 15;
    return newTotal;
  };

  useEffect(() => {
    basket.length > 0 && setTotal(shouldApplyDiscount(calculateTotal(basket)));
  }, [basket]);

  const hasShippableItems = () =>
    basket.some((item) => !item.fields.isDownload);

  const hasDownloads = () => basket.some((item) => item.fields.isDownload);

  return (
    <div ref={checkoutRef} style={{ height: height }} className='checkout'>
      <div className={"checkout__header"}>
        <span className='checkout__count'>
          {basket.length === 1
            ? `${basket.length} selected item:`
            : `${basket.length} selected items:`}
        </span>
        <button
          disabled={basket.length === 0}
          onClick={() => {
            setShowPP(!showPP);
            setShowCheckout(!showCheckout);
          }}
          className={!showPP ? "checkout__button" : "checkout__button--small"}
        >
          {!showPP ? "CHECKOUT" : "hide checkout"}
        </button>
      </div>
      {showPP && (
        <div className='checkout__paypal'>
          <hr />
          <h4>In The Basket:</h4>
          {basket.map((item) => (
            <p key={item.fields.title}> {item.fields.title}</p>
          ))}

          <h4>Total: €{total}</h4>
          <hr />
          <div className='checkout__inputs'>
            <input
              placeholder='Please enter your name'
              onChange={(e) => setName(e.target.value)}
            />
            <input
              placeholder='Please enter your Email'
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />
            {hasShippableItems() && (
              <div className='checkout__inputs__address'>
                <input
                  onChange={(e) => setAddress1(e.target.value)}
                  placeholder='Address line 1'
                />
                <input
                  onChange={(e) => setAddress2(e.target.value)}
                  placeholder='Address line 2'
                />
                <input
                  onChange={(e) => setCity(e.target.value)}
                  placeholder='City'
                />
                <input
                  onChange={(e) => setPostCode(e.target.value)}
                  placeholder='Postcode'
                />
                <input
                  onChange={(e) => setStateProvince(e.target.value)}
                  placeholder='Province / State'
                />
                <input
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder='Country'
                />
              </div>
            )}
            <button
              disabled={!name || !email}
              className={
                !continuing ? "checkout__button" : "checkout__button--small"
              }
              onClick={() => setContinuing(!continuing)}
            >
              {!continuing ? "continue to payment" : "hide payment methods"}
            </button>
          </div>
          {continuing && (
            <div className='checkout__paypal'>
              <hr />
              <PayPalButton
                price={total}
                description={`Purchase from www.adamwoolf.com`}
                clientId={paypalClientId}
                currency='EUR'
                paySubmit={handlePayment}
              />
            </div>
          )}
          <button
            onClick={closeAll}
            className='checkout__button--small cancel-button'
          >
            cancel: continue shopping
          </button>
          {/* <button onClick={handlePayment}>TEST</button> */}
        </div>
      )}
    </div>
  );
};

export default Checkout;
