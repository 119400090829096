import React, { useState, useEffect } from "react";
import YoutubeVid from "../../components/YoutubeVid/YoutubeVid";
import Hero from "../../components/Hero/Hero";
import { getYoutube } from "../../contentful";
import kbdPic from "../../assets/kbdBgd.JPG";
const Home = () => {
  const [youtube, setYoutube] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getYoutube().then((data) =>
      setYoutube(data.filter((v) => v.fields.homepage))
    );
  }, []);
  return (
    <>
      <Hero />
      <div>
        <div className='homepage__intro text-box'>
          <p>
            Welcome to the website of Kathryn Cok, internationally renowned
            historical keyboard specialist: Performer, Teacher, and Researcher.
            Here you can find information about Kathryn’s diverse activities as
            a soloist and chamber-musician, enjoy her various audio and video
            recordings and find out about upcoming live performances.
          </p>
        </div>
        <div className='homepage__videos'>
          {youtube.map((v) => (
            <YoutubeVid className='homepage__vid' video={v.fields.code} />
          ))}
        </div>
        <img src={kbdPic} className='homepage__footer-image' />
      </div>
    </>
  );
};

export default Home;
