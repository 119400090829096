import React from "react";
import "./NavigationStyles.scss";
import { NavLink } from "react-router-dom";

export const ROUTES = [
  { label: "Home", path: "/" },
  { label: "Biography", path: "/biog" },
  { label: "Ensembles", path: "/ensembles" },
  // { label: "Solo Programs", path: "/solo" },
  { label: "Recordings", path: "/recordings" },
  { label: "Research & Teaching", path: "/research" },
  { label: "Store", path: "/store" },
  { label: "Contact", path: "/contact" },
];

const Navigation = () => {
  return (
    <nav className='nav'>
      {ROUTES.map((r) => (
        <NavLink
          activeClassName='active'
          exact
          className='nav-link'
          to={r.path}
        >
          {r.label}
        </NavLink>
      ))}
    </nav>
  );
};

export default Navigation;
