import React, { useEffect } from "react";
import "./SideDrawerStyles.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../Navigation/Navigation";

const Sidedrawer = ({ open, onClose }) => {
  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "scroll";
  //   }
  // }, [open]);
  return (
    <div className={!open ? "side-drawer" : "side-drawer side-drawer--open"}>
      <nav className='side-drawer__nav'>
        {ROUTES.map((r) => (
          <Link onClick={onClose} className='side-drawer__nav-link' to={r.path}>
            {r.label}{" "}
          </Link>
        ))}
      </nav>{" "}
    </div>
  );
};

export default Sidedrawer;
