import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./TestimonialsSliderStyles.scss";
import { getReviews } from "../../contentful";

const SlideItem = ({ item }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.8 }}
    className='slide-item'
  >
    {item.fields.image && (
      <img
        className='testimonial__image'
        src={item.fields.image.fields.file.url}
      />
    )}
    <h3 className='testimonial__quote'>{item.fields.quote}</h3>
    <h6 className='testimonial__source'>{item.fields.source}</h6>
  </motion.div>
);

const TimerSlideMs = 7000;

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tests, setTests] = useState([]);
  const [length, setLength] = useState();

  useEffect(() => {
    getReviews().then((data) => setTests(data));
  }, []);

  useEffect(() => {
    tests.length && setLength(tests.length);
    clearInterval(timerSlider.current);

    timerSlider.current = setInterval(
      () =>
        setCurrentIndex((currentIndex) => (currentIndex + 1) % tests.length),
      TimerSlideMs
    );

    return () => {
      clearInterval(timerSlider.current);
    };
  }, [tests]);

  const timerSlider = useRef(null);

  return (
    <div className='testimonials-container'>
      <div style={{ maxWidth: "100%" }}>
        {tests.map(
          (item, itemIndex) =>
            currentIndex === itemIndex && (
              <SlideItem key={itemIndex} item={item} />
            )
        )}
      </div>
    </div>
  );
};

export default Testimonials;
