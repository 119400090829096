import React, { useState, useEffect } from "react";
import Navigation from "../Navigation/Navigation";
import "./HeaderStyles.scss";
import Sidedrawer from "../Sidedrawer/Sidedrawer";

const Header = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    setScrolled(window.scrollY > 70);
  };

  return (
    <header className={!scrolled ? "header" : "header header--scrolled"}>
      <h1>Kathryn Cok - Historical Keyboard Specialist </h1>
      <Navigation />
      <button
        onClick={() => setSideDrawerOpen(!sideDrawerOpen)}
        className='menu-toggle'
      >
        {!sideDrawerOpen ? "MENU" : "CLOSE MENU"}
      </button>
      <Sidedrawer
        open={sideDrawerOpen}
        onClose={() => setSideDrawerOpen(false)}
      />
    </header>
  );
};

export default Header;
