import React, { useState, useEffect } from "react";
import { getEnsembles } from "../../contentful";
import PageTitle from "../../components/PageTitle/PageTitle";
import marked from "marked";
import "./EnsemblesStyles.scss";

const Ensembles = () => {
  const [ensembles, setEnsembles] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getEnsembles().then((data) => setEnsembles(data));
  }, []);
  return (
    <div className='page'>
      <PageTitle title='Ensembles' />
      <div className='ensembles'>
        {ensembles.map((e) => (
          <div className='ensembles__ensemble'>
            <div className='ensembles__ensemble__content'>
              <div className='ensembles__ensemble__image'>
                <h2 className='ensembles__ensemble__title'>{e.fields.title}</h2>

                <img src={e.fields.image.fields.file.url} />
              </div>
              <div
                className='ensembles__ensemble__text'
                dangerouslySetInnerHTML={{ __html: marked(e.fields.text) }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ensembles;
