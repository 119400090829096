import React from "react";
import "./PageTitleStyles.scss";

const PageTitle = ({ title, white }) => {
  return (
    <div className={!white ? "page-title" : "page-title white"}>
      <h1>{title}</h1>
    </div>
  );
};

export default PageTitle;
